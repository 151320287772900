import React from "react";
import { useAuth0 } from "../auth/react-auth0-spa";
import { Redirect } from "react-router-dom";

function Home() {
  const { isAuthenticated } = useAuth0();

  return (
    <div>
      {isAuthenticated ? (
        <Redirect to="/register" />
      ) : (
        <div className="container">
          <div className="jumbotron">
            <h1 className="display-3">
              Macquarie Point Development Corporation
            </h1>
            <p className="lead">Risk Management System</p>
            <hr className="my-4" />
            <p>
              Please login to access the application. If you don't have login
              details, please see your manager.
            </p>
          </div>
          <div className="container"></div>
        </div>
      )}
    </div>
  );
}

export default Home;

import Axios from "axios";

/**
 * Fetches the user from the server and updates the riskStore with the user data.
 *
 * @param {Function} getTokenSilently - Auth0 function to retrieve the token.
 * @param {Object} user - Auth0 user object containing user information (e.g., sub).
 * @param {Object} riskStore - Store for managing risk-related state.
 */
export const getUser = async (getTokenSilently, user, riskStore) => {
  try {
    const token = await getTokenSilently();

    const response = await Axios({
      method: "get",
      url: `api/users/user?id=${user.sub}`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    riskStore.user = response.data;
  } catch (error) {
    console.error("Failed to fetch user:", error);
  }
};

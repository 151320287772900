import React, { Component } from "react";

class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear(); // Get the current year dynamically
    return (
      <div className="footerDiv">
        <footer>
          <div className="text-center">
            <p>&copy; {currentYear} Macquarie Point Development Corporation</p>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;

import { makeAutoObservable, runInAction } from "mobx";

class lessonStore {
  constructor() {
    // Automatically makes all properties observable and methods actions
    makeAutoObservable(this);
  }

  lessonList = [];
  lessonDetail = {};
  origLessonDetail = {};
  snapshot = {};
  updateComment = "";
  archiveCheck = "";
  settings = {};
  user = {};
  users = [];

  newLesson = {
    whatWorked: "",
    whatImproved: "",
    rating: {
      label: "Good",
      color: { label: "Good", value: "#00cd00" },
    },
    project: "",
    area: "",
    dateIdentified: Date.now(),
    originator: "",
    notes: "",
    lessonHistory: [],
    archived: false,
  };

  updateSnapshot = (snapshot) => {
    if (snapshot.oldLesson === undefined) {
      snapshot.oldLesson = this.newLesson;
    }
    runInAction(() => {
      this.snapshot = snapshot;
    });
  };

  handleUpdateComment = (value) => {
    runInAction(() => {
      this.updateComment = value;
    });
  };

  handleUpdateArchiveCheck = (value) => {
    runInAction(() => {
      this.archiveCheck = value;
    });
  };

  handleSimpleFormChange = (key, value) => {
    runInAction(() => {
      this.lessonDetail[key] = value;
    });
  };

  handleSimpleFormChange2 = (broadKey, narrowKey, value) => {
    runInAction(() => {
      this.lessonDetail[broadKey][narrowKey] = value;
    });
  };

  handleTypeAhead = (value, key) => {
    runInAction(() => {
      this.lessonDetail[key] = value;
    });
  };

  handleTypeAhead2Key = (value, broadKey, narrowKey) => {
    runInAction(() => {
      this.lessonDetail[broadKey][narrowKey] = value;
    });
  };

  addNewLine = (broadKey, narrowKey) => {
    runInAction(() => {
      const tempIndex = this.lessonDetail[broadKey][narrowKey].length + 1;
      this.lessonDetail[broadKey][narrowKey].push({
        index: tempIndex,
        detail: "",
      });
    });
  };

  addNewLine3 = (broadKey, midKey, narrowKey) => {
    runInAction(() => {
      const tempIndex =
        this.lessonDetail[broadKey][midKey][narrowKey].length + 1;
      this.lessonDetail[broadKey][midKey][narrowKey].push({
        index: tempIndex,
        detail: "",
      });
    });
  };

  editLine = (broadKey, narrowKey, index, content) => {
    runInAction(() => {
      this.lessonDetail[broadKey][narrowKey][index].detail = content;
    });
  };

  editLine3 = (broadKey, midKey, narrowKey, index, content) => {
    runInAction(() => {
      this.lessonDetail[broadKey][midKey][narrowKey][index].detail = content;
    });
  };

  deleteLine = (broadKey, narrowKey, index) => {
    runInAction(() => {
      // const arrayIndex = this.riskDetail[broadKey][narrowKey].findIndex(
      //   (item) => item.id === id
      // );
      this.lessonDetail[broadKey][narrowKey].splice(index, 1);
    });
  };

  deleteLine3 = (broadKey, midKey, narrowKey, index) => {
    runInAction(() => {
      this.lessonDetail[broadKey][midKey][narrowKey].splice(index, 1);
    });
  };
}

export default new lessonStore();

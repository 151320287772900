import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../components/auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Spinner from "../layout/Spinner";
import moment from "moment-timezone";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import CustomToolbar from "../../utils/customToolbar";

const IssuesRegister = (props) => {
  const [dataLoaded, setdataLoaded] = useState(false);
  const [showLayoutSave, setShowLayoutSave] = useState(false);
  const { getTokenSilently } = useAuth0();
  const apiRef = useGridApiRef();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const token = await getTokenSilently();

    axios({
      method: "get",
      url: "/api/issue/all",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((issues) => {
        props.issuesStore.issueList = issues.data;
        // console.log(issues.data[0]);
        setdataLoaded(true);
        axios({
          method: "get",
          url: "/api/settings/getall",
          headers: {
            accepts: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((settings) => {
            props.riskStore.settings = settings.data;
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const rowEvents = (id) => {
    props.history.push(`/issue/${id}`);
  };

  return dataLoaded ? (
    <div>
      <br />
      <h1 className="register-heading">Issues Register</h1>
      <div className="row">
        <div className="col-md-4">
          <p className="register-text">
            Please use the column headings to sort the register. To add a new
            issue, click the button to the right.
          </p>
        </div>
        <div className="col-md-6"></div>
        <div className="col-md-2">
          <button
            className="btn btn-primary"
            onClick={() => props.history.push("/issue/new")}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> New Issue
          </button>
        </div>
      </div>

      <DataGridPro
        apiRef={apiRef}
        pagination
        pageSizeOptions={[25, 50, 100]}
        columns={issuesColumns}
        rows={props.issuesStore.issueList}
        getRowHeight={() => "auto"}
        getRowId={(row) => row._id}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0
            ? "datagrid-even"
            : "datagrid-odd"
        }
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#7fd1f1",
          },
        }}
        onRowDoubleClick={(params) => {
          rowEvents(params.id);
        }}
        slots={{
          toolbar: () => (
            <CustomToolbar
              showSaveButton={showLayoutSave}
              setShowSaveButton={setShowLayoutSave}
              register={"issue"}
              apiRef={apiRef}
              riskStore={props.riskStore}
              getTokenSilently={getTokenSilently}
            />
          ),
        }}
        initialState={
          props.riskStore.user?.user_metadata?.registers?.issue
            ? props.riskStore.user.user_metadata.registers.issue // Use the value if it exists
            : undefined // Do not set initialState if it doesn't exist
        }
        onColumnOrderChange={() => setShowLayoutSave(true)}
        onColumnVisibilityModelChange={() => setShowLayoutSave(true)}
        onColumnWidthChange={() => setShowLayoutSave(true)}
        onFilterModelChange={() => setShowLayoutSave(true)}
        onSortModelChange={() => setShowLayoutSave(true)}
        onPaginationModelChange={() => setShowLayoutSave(true)}
      />
    </div>
  ) : (
    <Spinner />
  );
};

const dateFormatter = (value) => {
  if (!value) return "";
  return `${moment(value).format("DD-MM-YYYY")}`;
};

const issuesColumns = [
  {
    field: "dateRaised",
    headerName: "Date Raised",
    headerClassName: "wrap-header-text",
    flex: 1,
    valueGetter: (value) => dateFormatter(value),
  },
  {
    field: "project",
    headerName: "Project",
    headerClassName: "wrap-header-text",
    flex: 1,
    valueGetter: (value) => value.value,
  },
  {
    field: "description",
    headerName: "Description",
    headerClassName: "wrap-header-text",
    flex: 3,
  },
  {
    field: "classification",
    headerName: "Classification",
    headerClassName: "wrap-header-text",
    flex: 2,
    valueGetter: (value) => value.value,
  },
  {
    field: "responsibleOfficer",
    headerName: "Responsible Officer",
    headerClassName: "wrap-header-text",
    flex: 2,
    valueGetter: (value) => value.value,
  },
  {
    field: "resolved",
    headerName: "Resolved",
    headerClassName: "wrap-header-text",
    flex: 1,
    type: "singleSelect",
    valueOptions: ["Yes", "No"],
    valueGetter: (value) => {
      if (value === true) {
        return "Yes";
      } else {
        return "No";
      }
    },
    renderCell: (params) => (
      <div
        className="colored-grid-cell"
        style={{
          backgroundColor:
            params.value === "Yes"
              ? "#00cd00"
              : params.value === "No"
              ? "#ff0000"
              : "",
        }}
      >
        {params.value}
      </div>
    ),
  },
];

export default inject("riskStore", "issuesStore")(observer(IssuesRegister));

import React, { useEffect, useState } from "react";
import { useAuth0 } from "../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import axios from "axios";
import { getUser } from "../../utils/userUtils";

import Spinner from "../layout/Spinner";
import CustomToolbar from "../../utils/customToolbar";

const Register = (props) => {
  const [dataLoaded, setdataLoaded] = useState(false);
  const [showLayoutSave, setShowLayoutSave] = useState(false);
  const { getTokenSilently, user } = useAuth0();
  const apiRef = useGridApiRef();

  useEffect(() => {
    getData();
    getUser(getTokenSilently, user, props.riskStore);
  }, []);

  const getData = async () => {
    const token = await getTokenSilently();

    axios({
      method: "get",
      url: "/api/risk/risklist",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((risks) => {
        props.riskStore.riskList = risks.data;
        // console.log("Risks successfully loaded.");
        setdataLoaded(true);
        axios({
          method: "get",
          url: "/api/settings/getall",
          headers: {
            accepts: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((settings) => {
            props.riskStore.settings = settings.data;
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const rowEvents = (id) => {
    props.history.push(`/risk/${id}`);
  };

  return dataLoaded ? (
    <div>
      <br />
      <h1 className="register-heading">Risk Register</h1>
      <div className="row">
        <div className="col-md-4">
          <p className="register-text">
            Please use the column headings to sort the register. To add a new
            task, click the button to the right.
          </p>
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-2"></div>
        <div className="col-md-2">
          <button
            className="btn btn-primary"
            onClick={() => props.history.push("/risk/new")}
            size={"sm"}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> New Risk
          </button>
        </div>
      </div>

      <DataGridPro
        pagination
        pageSizeOptions={[25, 50, 100]}
        columns={risksColumns}
        rows={props.riskStore.riskList}
        getRowHeight={() => "auto"}
        // getRowHeight={() => 200}
        // getEstimatedRowHeight={() => 200}
        getRowId={(row) => row._id}
        apiRef={apiRef}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0
            ? "datagrid-even"
            : "datagrid-odd"
        }
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#7fd1f1",
          },
        }}
        onRowDoubleClick={(params) => {
          rowEvents(params.id);
        }}
        slots={{
          toolbar: () => (
            <CustomToolbar
              showSaveButton={showLayoutSave}
              setShowSaveButton={setShowLayoutSave}
              register={"risk"}
              apiRef={apiRef}
              riskStore={props.riskStore}
              getTokenSilently={getTokenSilently}
            />
          ),
        }}
        initialState={
          props.riskStore.user?.user_metadata?.registers?.risk
            ? props.riskStore.user.user_metadata.registers.risk // Use the value if it exists
            : undefined // Do not set initialState if it doesn't exist
        }
        onColumnOrderChange={() => setShowLayoutSave(true)}
        onColumnVisibilityModelChange={() => setShowLayoutSave(true)}
        onColumnWidthChange={() => setShowLayoutSave(true)}
        onFilterModelChange={() => setShowLayoutSave(true)}
        onSortModelChange={() => setShowLayoutSave(true)}
        onPaginationModelChange={() => setShowLayoutSave(true)}
      />
    </div>
  ) : (
    <Spinner />
  );
};

const risksColumns = [
  {
    field: "riskName",
    headerName: "Risk",
    headerClassName: "wrap-header-text",
    flex: 3,
  },
  {
    field: "riskCategoryBroad",
    headerName: "Broad Risk Category",
    headerClassName: "wrap-header-text",
    valueGetter: (value) => value.value,
    flex: 1,
  },
  {
    field: "riskCategoryNarrow",
    headerName: "Narrow Risk Category",
    headerClassName: "wrap-header-text",
    valueGetter: (value) => value.value,
    flex: 1,
  },
  {
    field: "riskOfficerPrimary",
    headerName: "Responsible Officer (Primary)",
    headerClassName: "wrap-header-text",
    valueGetter: (value) => value.value,
    flex: 1,
  },
  {
    field: "grossRiskAssessment",
    headerName: "Gross Risk Rating",
    headerClassName: "wrap-header-text",
    valueGetter: (value) => value.grossRiskRating.label,
    flex: 0.5,
    type: "singleSelect",
    valueOptions: ["Low", "Mod", "High", "Ext"],
    renderCell: (
      params // Low, Mod, High, Ext
    ) => (
      <div
        className="colored-grid-cell"
        style={{
          backgroundColor:
            params.value === "Low"
              ? "#00cd00"
              : params.value === "Mod"
              ? "#ffff00"
              : params.value === "High"
              ? "#ffc100"
              : "#ff0000",
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: "netRiskAssessment",
    headerName: "Net Risk Rating",
    headerClassName: "wrap-header-text",
    valueGetter: (value) => value.netRiskRating.label,
    flex: 0.5,
    type: "singleSelect",
    valueOptions: ["Low", "Mod", "High", "Ext"],
    renderCell: (
      params // Low, Mod, High, Ext
    ) => (
      <div
        className="colored-grid-cell"
        style={{
          backgroundColor:
            params.value === "Low"
              ? "#00cd00"
              : params.value === "Mod"
              ? "#ffff00"
              : params.value === "High"
              ? "#ffc100"
              : "#ff0000",
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: "archived",
    headerName: "Archived",
    headerClassName: "wrap-header-text",
    valueGetter: (value) => {
      if (value === "true") {
        return "Archived";
      } else {
        return "Active";
      }
    },
    flex: 0.5,
    type: "singleSelect",
    valueOptions: ["Active", "Archived"],
    renderCell: (params) => (
      <div
        className="colored-grid-cell"
        style={{
          backgroundColor:
            params.value === "Active"
              ? "#00cd00"
              : params.value === "Archived"
              ? "#ff0000"
              : "",
        }}
      >
        {params.value}
      </div>
    ),
  },
];

export default inject("riskStore")(observer(Register));

import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../components/auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Spinner from "../layout/Spinner";
import moment from "moment-timezone";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import CustomToolbar from "../../utils/customToolbar";

const Register = (props) => {
  const [dataLoaded, setdataLoaded] = useState(false);
  const [showLayoutSave, setShowLayoutSave] = useState(false);
  const { getTokenSilently } = useAuth0();
  const apiRef = useGridApiRef();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const token = await getTokenSilently();

    axios({
      method: "get",
      url: "/api/lesson/all",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((lessons) => {
        props.lessonStore.lessonList = lessons.data;
        // console.log(lessons.data[0]);
        setdataLoaded(true);
        axios({
          method: "get",
          url: "/api/settings/getall",
          headers: {
            accepts: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((settings) => {
            props.riskStore.settings = settings.data;
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const rowEvents = (id) => {
    props.history.push(`/lesson/${id}`);
  };

  return dataLoaded ? (
    <div>
      <br />
      <h1 className="register-heading">Lessons Learned Register</h1>
      <div className="row">
        <div className="col-md-4">
          <p className="register-text">
            Please use the column headings to sort the register. To add a new
            lesson, click the button to the right.
          </p>
        </div>
        <div className="col-md-6"></div>
        <div className="col-md-2">
          <button
            className="btn btn-primary"
            onClick={() => props.history.push("/lesson/new")}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> New Lesson
          </button>
        </div>
      </div>

      <DataGridPro
        apiRef={apiRef}
        columns={lessonsColumns}
        rows={props.lessonStore.lessonList}
        pagination
        pageSizeOptions={[10, 25, 100]}
        getRowHeight={() => "auto"}
        getRowId={(row) => row._id}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0
            ? "datagrid-even"
            : "datagrid-odd"
        }
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#7fd1f1",
          },
        }}
        onRowDoubleClick={(params) => {
          rowEvents(params.id);
        }}
        slots={{
          toolbar: () => (
            <CustomToolbar
              showSaveButton={showLayoutSave}
              setShowSaveButton={setShowLayoutSave}
              register={"lesson"}
              apiRef={apiRef}
              riskStore={props.riskStore}
              getTokenSilently={getTokenSilently}
            />
          ),
        }}
        initialState={
          props.riskStore.user?.user_metadata?.registers?.lesson
            ? props.riskStore.user.user_metadata.registers.lesson // Use the value if it exists
            : undefined // Do not set initialState if it doesn't exist
        }
        onColumnOrderChange={() => setShowLayoutSave(true)}
        onColumnVisibilityModelChange={() => setShowLayoutSave(true)}
        onColumnWidthChange={() => setShowLayoutSave(true)}
        onFilterModelChange={() => setShowLayoutSave(true)}
        onSortModelChange={() => setShowLayoutSave(true)}
        onPaginationModelChange={() => setShowLayoutSave(true)}
      />
    </div>
  ) : (
    <Spinner />
  );
};

const dateFormatter = (value) => {
  if (!value) return "";
  return `${moment(value).format("DD-MM-YYYY")}`;
};

const archiveFormatter1 = (value) => {
  if (value === true) {
    return "Archived";
  } else {
    return "Active";
  }
};

const lessonsColumns = [
  {
    field: "project",
    headerName: "Project",
    headerClassName: "wrap-header-text",
    valueGetter: (value) => value.value,
    flex: 1,
  },
  {
    field: "area",
    headerName: "Area",
    headerClassName: "wrap-header-text",
    valueGetter: (value) => value.label,
    flex: 1.5,
  },
  {
    field: "dateIdentified",
    headerName: "Date Identified",
    headerClassName: "wrap-header-text",
    valueGetter: (value) => dateFormatter(value),
    flex: 1,
  },
  {
    field: "originator",
    headerName: "Originator",
    headerClassName: "wrap-header-text",
    valueGetter: (value) => value.value,
    flex: 1,
  },
  {
    field: "whatWorked",
    headerName: "What Worked Well?",
    headerClassName: "wrap-header-text",
    flex: 3,
  },
  {
    field: "whatImproved",
    headerName: "What can be Improved?",
    headerClassName: "wrap-header-text",
    flex: 3,
  },
  {
    field: "rating",
    headerName: "Rating",
    headerClassName: "wrap-header-text",
    flex: 1,
    type: "singleSelect",
    valueOptions: ["Good", "Average", "Poor"],
    valueGetter: (value) => value.label,
    renderCell: (params) => {
      let backGroundColor = "";
      switch (params.value) {
        case "Good":
          backGroundColor = "#00cd00";
          break;
        case "Average":
          backGroundColor = "#ffc100";
          break;
        default:
          backGroundColor = "#ff0000";
      }

      return (
        <div
          className="colored-grid-cell"
          style={{
            backgroundColor: backGroundColor,
          }}
        >
          {params.value}
        </div>
      );
    },
  },
  {
    field: "archived",
    headerName: "Archived",
    headerClassName: "wrap-header-text",
    flex: 1,
    type: "singleSelect",
    valueOptions: ["Active", "Archived"],
    valueGetter: (value) => archiveFormatter1(value),
    renderCell: (params) => (
      <div
        className="colored-grid-cell"
        style={{
          backgroundColor:
            params.value === "Active"
              ? "#00cd00"
              : params.value === "Archived"
              ? "#ff0000"
              : "",
        }}
      >
        {params.value}
      </div>
    ),
  },
];

export default inject("riskStore", "lessonStore")(observer(Register));

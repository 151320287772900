import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import Axios from "axios";
import { useState } from "react";

const CustomToolbar = ({
  showSaveButton,
  setShowSaveButton,
  apiRef,
  register,
  riskStore,
  getTokenSilently,
}) => {
  const [saving, setSaving] = useState(false);

  const saveLayout = async () => {
    if (register && apiRef.current) {
      setSaving(true);
      try {
        const state = apiRef.current.exportState();

        // Set layout data on User in store
        if (!riskStore.user.user_metadata) riskStore.user.user_metadata = {};
        if (!riskStore.user.user_metadata.registers)
          riskStore.user.user_metadata.registers = {};
        riskStore.user.user_metadata.registers[register] = state;

        // Update Auth0 User with Metadata
        const token = await getTokenSilently();

        const response = await Axios({
          method: "patch",
          url: `api/users/update-screen-prefs?id=${riskStore.user.user_id}`,
          headers: {
            accepts: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: {
            registers: riskStore.user.user_metadata.registers,
          },
        });
        setShowSaveButton(false);
        setSaving(false);
        toast.success(`${register} layout saved successfully.`);
      } catch {
        toast.error("Error saving layout. Please try again later.");
      }
    } else {
      toast.error("Error saving layout. Please try again later.");
    }
  };

  return (
    <GridToolbarContainer className="custom-toolbar">
      {/* Left-justified toolbar content */}
      <div></div>

      {/* Right-justified toolbar content */}
      <div className="right-toolbar-content">
        <GridToolbarFilterButton />
        <GridToolbarExport />
        {showSaveButton && (
          <Button
            onClick={() => {
              saveLayout();
            }}
            disabled={saving}
            size={"small"}
          >
            Save Layout
          </Button>
        )}
      </div>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;

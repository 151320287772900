import React from "react";
import { inject, observer } from "mobx-react";
import moment from "moment-timezone";
import { DataGridPro } from "@mui/x-data-grid-pro";

const LessonHistory = (props) => {
  const rowEvents = (id) => {
    props.history.push(`/lessonhistory/${id}`);
  };

  const dateFormatter = (value) => {
    return moment(value).tz("Australia/Hobart").format("DD MMMM YYYY h:mm a");
  };

  const historyColumns = [
    {
      field: "date",
      headerName: "Date",
      valueGetter: (value) => dateFormatter(value),
      flex: 2,
    },
    {
      field: "changeUser",
      headerName: "User",
      valueGetter: (value) => `${value.userName} (${value.userEmail})`,
      flex: 4,
    },
    {
      field: "snapshotID",
      headerName: "Commit ID",
      flex: 2,
    },
  ];

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h5 className="form-heading">Lesson History</h5>
        </div>
      </div>
      <br />
      <div className="historyTableDiv">
        <div className="row">
          <div className="col-md-12">
            <p>
              Below is a list of ALL the change events for this lesson. Use the
              fields to filter, and click a row to view the detailed changes
              that were made.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <DataGridPro
              columns={historyColumns}
              rows={props.lessonStore.lessonDetail.lessonHistory}
              getRowId={(row) => row.snapshotID}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "datagrid-even"
                  : "datagrid-odd"
              }
              sx={{
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#7fd1f1",
                },
              }}
              onRowDoubleClick={(params) => {
                rowEvents(params.id);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("lessonStore")(observer(LessonHistory));
